<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'
import { onMounted } from 'vue'

defineLayout({
  label: 'Sandpiper Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader()

const { currentResort } = await getCommonLayoutData(
  isVoixTemplatizer,
  [
    'resorts',
    'brands',
    'footerCompanyMenu',
    'footerResourcesMenu',
    'footerTermsMenu',
  ],
)

const { data: footerTermsMenu } = await $voixNuxtApi(`/api/sites/42/menus/Sandpiper%20Footer%20Terms%20Menu`)

const pageStore = usePageStore()

useHead({
  htmlAttrs: {
    lang: pageStore.currentPage?.language_code ?? 'en',
  },
  link: [
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'shortcut icon',
      type: 'image/png',
      href: '/media/favicon.png',
    },
    { rel: 'apple-touch-icon', sizes: '120x120', href: '/imgs/sandpiper/favicons/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/imgs/sandpiper/favicons/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/imgs/sandpiper/favicons/favicon-16x16.png' },
    { rel: 'manifest', href: '/imgs/sandpiper/favicons/site.webmanifest' },
    { rel: 'mask-icon', href: '/imgs/sandpiper/favicons/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'shortcut icon', href: '/imgs/sandpiper/favicons/favicon.ico' },
  ],
  script: [
    {
      children: '(function(){ var s = document.createElement(\'script\'); var h = document.querySelector(\'head\') || document.body; s.src = \'https://acsbapp.com/apps/app/dist/js/app.js\'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : \'\', footerHtml : \'\', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : \'en\', position : \'right\', leadColor : \'#146FF8\', triggerColor : \'#146FF8\', triggerRadius : \'50%\', triggerPositionX : \'right\', triggerPositionY : \'bottom\', triggerIcon : \'people\', triggerSize : \'bottom\', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : \'small\', triggerPositionX : \'right\', triggerPositionY : \'bottom\', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : \'20\' } }); }; h.appendChild(s); })();',
      body: true,
    },
  ],
})

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#cb9110' })
</script>

<template>
  <div
    class="text-grey-darker bg-abs-white font-sandpiper-sanserif"
  >
    <div id="portal-destination" transition="fade-transition" />

    <SandpiperNavbar :menu="currentResort?.data?.menu" />
    <slot />

    <SandpiperFooter :footer-terms-menu="footerTermsMenu" />
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/sandpiper.scss';
</style>
