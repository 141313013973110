<script lang="ts" setup>
import { computed, ref } from 'vue'
import axios from 'axios'
import Cookies from 'js-cookie'
import { usePageStore } from '@voix/store/pageStore'

import { useI18n } from 'vue-i18n'

const props = defineProps({
  formId: {
    type: String,
    required: false,
  },
})

const { t } = useI18n({})

const form = ref({
  formId: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  IsTravelAgent: 'No',
  TCAgree: 'No',
  Language_Code: 'EN',
  country: '',
})
const success = ref({
  show: false,
  message: 'success',
})
const error = ref({
  message: 'error',
})
const formInvalid = ref(false)
const firstNameInvalid = ref(false)
const lastNameInvalid = ref(false)
const emailInvalid = ref(false)
const countryInvalid = ref(false)
const shouldShowError = ref(false)
const agreeToTermsInvalid = ref(false)
const validationErrors = ref([])

const consentMessage = computed(() => {
  return form.value.country === 'CA'
    ? t('i-want-to-receive-electronic-communications-ca')
    : t('i-want-to-receive-electronic-communications')
})

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

const highlightStyle = computed(() => {
  const siteId = currentPage.value?.site_id
  if (siteId === 2 || siteId === 3)
    return { 'background-color': 'rgb(0, 189, 198)' }

  if (siteId === 5)
    return { 'background-color': '#30a298' }

  if (siteId === 8)
    return { 'background-color': '#662d91' }

  return {}
})

function submit() {
  if (formValidates()) {
    const { $playa } = useNuxtApp()
    const instance = axios.create({})
    const formData = Object.assign({}, form.value)

    if (props.formId)
      formData.formId = props.formId

    else
      formData.formId = 'DD7F66E7-24DF-42DE-A02F-D2401DF49571'

    instance
      .post(`${$playa.rest_api_base_url}/divinci/web-email-registration`, formData)
      .then(() => {
        showSuccess()
      })
      .catch(() => {
        showError(t('there-was-a-problem'))
      })
  }
}

function formValidates() {
  // reset
  formInvalid.value = false
  validationErrors.value = []

  if (form.value.TCAgree === 'No') {
    agreeToTermsInvalid.value = true
    formInvalid.value = true
  }
  else {
    agreeToTermsInvalid.value = false
  }
  if (form.value.firstName === null || form.value.firstName === '') {
    firstNameInvalid.value = true
    formInvalid.value = true
  }
  else {
    firstNameInvalid.value = false
  }
  if (form.value.lastName === null || form.value.lastName === '') {
    lastNameInvalid.value = true
    formInvalid.value = true
  }
  else {
    lastNameInvalid.value = false
  }
  if (form.value.country === '') {
    countryInvalid.value = true
    formInvalid.value = true
  }
  else {
    countryInvalid.value = false
  }
  if (!validEmail()) {
    emailInvalid.value = true
    formInvalid.value = true
  }
  else {
    emailInvalid.value = false
  }

  return formInvalid.value === false
}

function validEmail() {
  return /\S+@\S+\.\S+/.test(form.value.emailAddress)
}

function showError(msg: string) {
  error.value.message = msg
  shouldShowError.value = true
}

function showSuccess() {
  success.value.show = true
  success.value.message = t('thank-you')
  Cookies.set('hide-pop-up', 'true', { expires: 99999999 })

  const dataLayer = window.dataLayer

  try {
    dataLayer.push({
      event: 'newsletter',
    })
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

form.value.Language_Code = currentPage.value?.language_code.toUpperCase()
  ? currentPage.value?.language_code.toUpperCase()
  : 'EN'
</script>

<template>
  <section id="newsletter-form">
    <div>
      <div>
        <h2>{{ $t('sign-up-to-save') }}</h2>
        <h4>{{ $t('receive-via-email') }}</h4>
        <form class="max-w-[700px]" @submit.prevent="submit">
          <div>
            <span v-if="firstNameInvalid" class="ml-4 font-bold text-red">{{ $t('first-name') }} {{ $t('required') }}</span>
            <span v-if="lastNameInvalid" class="ml-4 font-bold text-red">{{ $t('last-name') }} {{ $t('required') }}</span>
            <span v-if="emailInvalid" class="ml-4 font-bold text-red">Email {{ $t('required') }}</span>
            <span v-if="countryInvalid" class="ml-4 font-bold text-red">{{ $t('country') }} {{ $t('required') }}</span>
            <span v-if="agreeToTermsInvalid" class="text-xs font-bold text-red">{{ $t('you-must-agree-to-terms-of-use') }}</span>
          </div>
          <div class="texts">
            <div class="input-group">
              <label for="first-name">First Name*</label>
              <input id="first-name" v-model="form.firstName" type="text" required>
            </div>
            <div class="input-group">
              <label for="last-name">Last Name*</label>
              <input id="last-name" v-model="form.lastName" type="text" required>
            </div>
            <div class="input-group">
              <label for="email">Email*</label>
              <input id="email" v-model="form.emailAddress" type="email" required>
            </div>
            <div class="input-group">
              <select
                id="country"
                v-model="form.country"
                name="country"
                required
                class="max-w-[300px]"
              >
                <option value="">
                  {{ $t('select-country') }}*
                </option>
                <option value="US">
                  United States
                </option>
                <option value="CA">
                  Canada
                </option>
                <option value="GB">
                  United Kingdom
                </option>
                <option value="AF">
                  Afghanistan
                </option>
                <option value="AX">
                  Aland Islands
                </option>
                <option value="AL">
                  Albania
                </option>
                <option value="DZ">
                  Algeria
                </option>
                <option value="AS">
                  American Samoa
                </option>
                <option value="AD">
                  Andorra
                </option>
                <option value="AO">
                  Angola
                </option>
                <option value="AI">
                  Anguilla
                </option>
                <option value="AQ">
                  Antarctica
                </option>
                <option value="AG">
                  Antigua &amp; Barbuda
                </option>
                <option value="AR">
                  Argentina
                </option>
                <option value="AM">
                  Armenia
                </option>
                <option value="AW">
                  Aruba
                </option>
                <option value="AU">
                  Australia
                </option>
                <option value="AT">
                  Austria
                </option>
                <option value="AZ">
                  Azerbaijan
                </option>
                <option value="BS">
                  Bahamas
                </option>
                <option value="BH">
                  Bahrain
                </option>
                <option value="BD">
                  Bangladesh
                </option>
                <option value="BB">
                  Barbados
                </option>
                <option value="BY">
                  Belarus
                </option>
                <option value="BE">
                  Belgium
                </option>
                <option value="BZ">
                  Belize
                </option>
                <option value="BJ">
                  Benin
                </option>
                <option value="BM">
                  Bermuda
                </option>
                <option value="BT">
                  Bhutan
                </option>
                <option value="BO">
                  Bolivia
                </option>
                <option value="BQ">
                  Bonaire
                </option>
                <option value="BA">
                  Bosnia And Herzegowina
                </option>
                <option value="BW">
                  Botswana
                </option>
                <option value="BV">
                  Bouvet Island
                </option>
                <option value="BR">
                  Brazil
                </option>
                <option value="IO">
                  British Indian Ocean Territory
                </option>
                <option value="VG">
                  British Virgin Islands
                </option>
                <option value="BN">
                  Brunei Darussalam
                </option>
                <option value="BG">
                  Bulgaria
                </option>
                <option value="BF">
                  Burkina Faso
                </option>
                <option value="BI">
                  Burundi
                </option>
                <option value="KH">
                  Cambodia
                </option>
                <option value="CM">
                  Cameroon
                </option>
                <option value="CV">
                  Cape Verde
                </option>
                <option value="KY">
                  Cayman Islands
                </option>
                <option value="CF">
                  Central African Republic
                </option>
                <option value="TD">
                  Chad
                </option>
                <option value="CD">
                  Channel Islands
                </option>
                <option value="CL">
                  Chile
                </option>
                <option value="CN">
                  China
                </option>
                <option value="CX">
                  Christmas Island
                </option>
                <option value="CC">
                  Cocos (keeling) Islands
                </option>
                <option value="CO">
                  Colombia
                </option>
                <option value="KM">
                  Comoros
                </option>
                <option value="CG">
                  Congo
                </option>
                <option value="CK">
                  Cook Islands
                </option>
                <option value="CR">
                  Costa Rica
                </option>
                <option value="CI">
                  Cote D'ivoire/ivory Coast
                </option>
                <option value="HR">
                  Croatia
                </option>
                <option value="CU">
                  Cuba
                </option>
                <option value="BW">
                  Curacoa
                </option>
                <option value="CY">
                  Cyprus
                </option>
                <option value="CZ">
                  Czech Republic
                </option>
                <option value="CI">
                  Cã´te D'ivoire
                </option>
                <option value="CD">
                  Democratic Republic Of The Congo
                </option>
                <option value="DK">
                  Denmark
                </option>
                <option value="DJ">
                  Djibouti
                </option>
                <option value="DM">
                  Dominica
                </option>
                <option value="DO">
                  Dominican Republic
                </option>
                <option value="TP">
                  East Timor
                </option>
                <option value="EC">
                  Ecuador
                </option>
                <option value="EG">
                  Egypt
                </option>
                <option value="SV">
                  El Salvador
                </option>
                <option value="EN">
                  England
                </option>
                <option value="GQ">
                  Equatorial Guinea
                </option>
                <option value="ER">
                  Eritrea
                </option>
                <option value="EE">
                  Estonia
                </option>
                <option value="ET">
                  Ethiopia
                </option>
                <option value="FO">
                  Faeroe Islands
                </option>
                <option value="FK">
                  Falkland Islands
                </option>
                <option value="ZZ">
                  Fictitious Points
                </option>
                <option value="FJ">
                  Fiji
                </option>
                <option value="FI">
                  Finland
                </option>
                <option value="FR">
                  France
                </option>
                <option value="GF">
                  French Guiana
                </option>
                <option value="PF">
                  French Polynesia
                </option>
                <option value="TF">
                  French Southern Territories
                </option>
                <option value="GA">
                  Gabon
                </option>
                <option value="GM">
                  Gambia
                </option>
                <option value="GE">
                  Georgia
                </option>
                <option value="DE">
                  Germany
                </option>
                <option value="GH">
                  Ghana
                </option>
                <option value="GI">
                  Gibralter
                </option>
                <option value="GR">
                  Greece
                </option>
                <option value="GL">
                  Greenland
                </option>
                <option value="GD">
                  Grenada
                </option>
                <option value="GP">
                  Guadeloupe
                </option>
                <option value="GU">
                  Guam
                </option>
                <option value="GT">
                  Guatemala
                </option>
                <option value="GG">
                  Guernsey
                </option>
                <option value="GN">
                  Guinea
                </option>
                <option value="GW">
                  Guinea-bissau
                </option>
                <option value="GY">
                  Guyana
                </option>
                <option value="HT">
                  Haiti
                </option>
                <option value="HM">
                  Heard And Mcdonald Islands
                </option>
                <option value="HN">
                  Honduras
                </option>
                <option value="HK">
                  Hong Kong
                </option>
                <option value="HU">
                  Hungary
                </option>
                <option value="IS">
                  Iceland
                </option>
                <option value="IN">
                  India
                </option>
                <option value="ID">
                  Indonesia
                </option>
                <option value="IR">
                  Iran
                </option>
                <option value="IQ">
                  Iraq
                </option>
                <option value="IE">
                  Ireland
                </option>
                <option value="IM">
                  Isle Of Man
                </option>
                <option value="IL">
                  Israel
                </option>
                <option value="IT">
                  Italy
                </option>
                <option value="JM">
                  Jamaica
                </option>
                <option value="JP">
                  Japan
                </option>
                <option value="JO">
                  Jordan
                </option>
                <option value="KZ">
                  Kazakstan
                </option>
                <option value="KE">
                  Kenya
                </option>
                <option value="KI">
                  Kiribati
                </option>
                <option value="KW">
                  Kuwait
                </option>
                <option value="KG">
                  Kyrgystan
                </option>
                <option value="LA">
                  Lao People's Democratic Republic
                </option>
                <option value="LV">
                  Latvia
                </option>
                <option value="LB">
                  Lebanon
                </option>
                <option value="LS">
                  Lesotho
                </option>
                <option value="LR">
                  Liberia
                </option>
                <option value="LY">
                  Libyan Arab Jamahiriya
                </option>
                <option value="LI">
                  Liechtenstein
                </option>
                <option value="LT">
                  Lithuania
                </option>
                <option value="LU">
                  Luxembourg
                </option>
                <option value="MO">
                  Macau
                </option>
                <option value="MK">
                  Macedonia (fyrom)
                </option>
                <option value="MG">
                  Madagascar
                </option>
                <option value="MW">
                  Malawi
                </option>
                <option value="MY">
                  Malaysia
                </option>
                <option value="MV">
                  Maldives
                </option>
                <option value="ML">
                  Mali
                </option>
                <option value="MT">
                  Malta
                </option>
                <option value="MH">
                  Marshall Islands
                </option>
                <option value="MQ">
                  Martinique
                </option>
                <option value="MR">
                  Mauritania
                </option>
                <option value="MU">
                  Mauritius
                </option>
                <option value="YT">
                  Mayotte
                </option>
                <option value="MX">
                  Mexico
                </option>
                <option value="FM">
                  Micronesia
                </option>
                <option value="MD">
                  Moldova
                </option>
                <option value="MC">
                  Monaco
                </option>
                <option value="MN">
                  Mongolia
                </option>
                <option value="MS">
                  Montserrat
                </option>
                <option value="MA">
                  Morocco
                </option>
                <option value="MZ">
                  Mozambique
                </option>
                <option value="MM">
                  Myanmar
                </option>
                <option value="NA">
                  Namibia
                </option>
                <option value="NR">
                  Nauru
                </option>
                <option value="NP">
                  Nepal
                </option>
                <option value="NL">
                  Netherlands
                </option>
                <option value="AN">
                  Netherlands Antilles
                </option>
                <option value="NC">
                  New Caledonia
                </option>
                <option value="NZ">
                  New Zealand
                </option>
                <option value="NI">
                  Nicaragua
                </option>
                <option value="NE">
                  Niger
                </option>
                <option value="NG">
                  Nigeria
                </option>
                <option value="NU">
                  Niue
                </option>
                <option value="XX">
                  No Country
                </option>
                <option value="NF">
                  Norfolk Island
                </option>
                <option value="KR">
                  North Korea
                </option>
                <option value="MP">
                  Northern Mariana Islands
                </option>
                <option value="NO">
                  Norway
                </option>
                <option value="OM">
                  Oman
                </option>
                <option value="PK">
                  Pakistan
                </option>
                <option value="PW">
                  Palau
                </option>
                <option value="PS">
                  Palestine, State Of
                </option>
                <option value="PA">
                  Panama
                </option>
                <option value="PG">
                  Papua New Guinea
                </option>
                <option value="PY">
                  Paraguay
                </option>
                <option value="PE">
                  Peru
                </option>
                <option value="PH">
                  Philippines
                </option>
                <option value="PN">
                  Pitcairn
                </option>
                <option value="PL">
                  Poland
                </option>
                <option value="PT">
                  Portugal
                </option>
                <option value="PR">
                  Puerto Rico
                </option>
                <option value="QA">
                  Qatar
                </option>
                <option value="RE">
                  Reunion
                </option>
                <option value="RO">
                  Romania
                </option>
                <option value="RU">
                  Russia
                </option>
                <option value="RW">
                  Rwanda
                </option>
                <option value="SX">
                  Saint Maarten
                </option>
                <option value="WS">
                  Samoa
                </option>
                <option value="SM">
                  San Marino
                </option>
                <option value="ST">
                  Sao Tome And Principe
                </option>
                <option value="SA">
                  Saudi Arabia
                </option>
                <option value="SN">
                  Senegal
                </option>
                <option value="SQ">
                  Serbia And Montenegro
                </option>
                <option value="SC">
                  Seychelles
                </option>
                <option value="SL">
                  Sierra Leone
                </option>
                <option value="SG">
                  Singapore
                </option>
                <option value="SK">
                  Slovakia
                </option>
                <option value="SI">
                  Slovenia
                </option>
                <option value="SB">
                  Solomon Islands
                </option>
                <option value="SO">
                  Somalia
                </option>
                <option value="ZA">
                  South Africa
                </option>
                <option value="GS">
                  South Georgia And The South Sandwich Islands
                </option>
                <option value="KP">
                  South Korea
                </option>
                <option value="ES">
                  Spain &amp; Canary Islands
                </option>
                <option value="LK">
                  Sri Lanka
                </option>
                <option value="SH">
                  St. Helena
                </option>
                <option value="KN">
                  St. Kitts And Nevis
                </option>
                <option value="LC">
                  St. Lucia
                </option>
                <option value="PM">
                  St. Pierre And Miquelon
                </option>
                <option value="VC">
                  St. Vincent And The Grenadines
                </option>
                <option value="SD">
                  Sudan
                </option>
                <option value="SR">
                  Suriname
                </option>
                <option value="SJ">
                  Svalbard And Jan Mayen Islands
                </option>
                <option value="SZ">
                  Swaziland
                </option>
                <option value="SE">
                  Sweden
                </option>
                <option value="CH">
                  Switzerland
                </option>
                <option value="SY">
                  Syrian Arab Republic
                </option>
                <option value="TW">
                  Taiwan
                </option>
                <option value="TJ">
                  Tajikistan
                </option>
                <option value="TZ">
                  Tanzania
                </option>
                <option value="TH">
                  Thailand
                </option>
                <option value="TG">
                  Togo
                </option>
                <option value="TK">
                  Tokelau
                </option>
                <option value="TO">
                  Tonga
                </option>
                <option value="TT">
                  Trinidad And Tobago
                </option>
                <option value="TN">
                  Tunisia
                </option>
                <option value="TR">
                  Turkey
                </option>
                <option value="TM">
                  Turkmenistan
                </option>
                <option value="TC">
                  Turks And Caicos Islands
                </option>
                <option value="TV">
                  Tuvalu
                </option>
                <option value="VI">
                  U.s. Virgin Islands
                </option>
                <option value="UG">
                  Uganda
                </option>
                <option value="UA">
                  Ukraine
                </option>
                <option value="AE">
                  United Arab Emirates
                </option>
                <option value="UM">
                  United States Minor Outlying Islands
                </option>
                <option value="UY">
                  Uruguay
                </option>
                <option value="UZ">
                  Uzbekistan
                </option>
                <option value="VU">
                  Vanuatu
                </option>
                <option value="VA">
                  Vatican City State
                </option>
                <option value="VE">
                  Venezuela
                </option>
                <option value="VN">
                  Viet Nam
                </option>
                <option value="WF">
                  Wallis And Futuna Islands
                </option>
                <option value="EH">
                  Western Sahara
                </option>
                <option value="YE">
                  Yemen
                </option>
                <option value="YU">
                  Yugoslavia
                </option>
                <option value="ZR">
                  Zaire
                </option>
                <option value="ZM">
                  Zambia
                </option>
                <option value="ZW">
                  Zimbabwe
                </option>
              </select>
            </div>
          </div>
          <div class="text-center mt-16">
            <button type="submit" class="py-2 px-12 text-white uppercase bg-nonPhotoBlue">
              {{ $t('subscribe') }}
            </button>
          </div>
          <div class="checkboxes-wrapper">
            <div class="checkbox">
              <input id="is-travel-agent" v-model="form.IsTravelAgent" type="checkbox">
              <label for="is-travel-agent">{{ $t('i-am-a-travel-agent') }}</label>
            </div>
            <div class="checkbox">
              <input id="consent" v-model="form.TCAgree" type="checkbox">
              <label for="consent">
                <span
                  v-if="form.country === 'CA'"
                  v-html="
                    $t(
                      'i-want-to-receive-electronic-communications-ca',
                    )
                  "
                />
                <span
                  v-else
                  v-html="
                    $t(
                      'i-want-to-receive-electronic-communications',
                    )
                  "
                />
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
